// zh-CN - used as 'zn' fallback
export const common_fallback = true

export const translations = {
  nomorebets: '投注结束',
  playtime: '上场时间',
  gameId: '赛事ID',
  noGames: '无可用赛事，请稍后再回来查看',
  Home: '主',
  Away: '客',
  Visitor: '客',
  betslip: {
    title: '投注单',
    bet: '下注',
    clear: '全部清除',
    clearResult: '清除',
    noSelections: '添加选项以开始投注',
    notLoggedIn: '登入后开始投注',
    refresh: '刷新',
    totalStake: '总投注金',
    noHistoryData: '没有投注资讯',
    openBets: '未派彩投注',
    betHistory: '投注记录',
    betHistoryWon: '赢',
    betHistoryLost: '输',
  },
  loading: {
    loading: '载入中',
    account: '登入帐号中',
    league: '载入联赛',
    gameweeks: '载入比赛时间表',
    markets: '载入市场',
    bethistory: '载入投注记录',
  },
  login: {
    loginRequired: '必须登录',
    loginToBet: '请登入后开始投注',
  },
  navigation: {
    back: '返回',
    close: '关闭',
  },
  soccerbet: {
    gameWeek: '赛事周',
    noGameWeeks: '没有比赛时间表，请稍后再查看',
    league: '联赛排名',
    table: {
      name: '名称',
      won: '赢',
      drawn: '和',
      lost: '输',
      points: '点数'
    }
  },
  video: {
    liveStreamAvailable: '有进行直播',
    logInToWatch: '请登录观看'
  },
  error: {
    error: '错误',
    404: '网页未找到',
    gameLaunchFailed: '无法启动',

    unknownErrorCode: '未知错误：{code}',
    minUnitStakeLimitExceeded: '最低投注额是{limit}',
    maxUnitStakeLimitExceeded: '最高投注额是{limit}',
    minTotalStakeLimitExceeded: '最低总投注额是{limit}',
    maxTotalStakeLimitExceeded: '最高总投注额是{limit}',
    notPlaced: '未投注',
    wagerDisallowed: '禁止下注',
    errorEventIsOff: '赛事已经开始'
  },

  // Markets
  'market.BTTS': '两队得分',
  'market.Both Teams To Score': '两队都进球',
  'market.Over / Under': '大/小',
  'market.Double Chance': '双重机会',
  'market.Match Result': '比赛结果',
  'market.Exact Goals': '正确比分',
  'market.Correct Score': '波胆',
  'market.Match Result Both Score': '比赛结果双方得分',
  'market.Result Und/Over 1.5 Total Goals': '结果大/小1.5总进球',
  'market.Result Und/Over 2.5 Total Goals': '结果大/小2.5总进球',
  'market.Under Over 1.5 Total Goals': '大/小1.5总进球',
  'market.Under Over 2.5 Total Goals': '大/小2.5总进球',
  'market.Under Over 3.5 Total Goals': '大/小3.5总进球',
  'market.Under Over 4.5 Total Goals': '大/小4.5总进球',
  'market.Total Goals 1 To 2': '总进球数1至2',
  'market.Total Goals 1 To 3': '总进球数1至3',
  'market.Total Goals 1 To 4': '总进球数1至4',
  'market.Total Goals 2 To 3': '总进球数2至3',
  'market.Total Goals 2 To 4': '总进球数2至4',
  'market.Under Over 1.5 Home Goals': '大/小1.5主队进球',
  'market.Under Over 1.5 Away Goals': '大/小1.5客队进球',
  'market.Home Team Exact Goals': '主队正确比分',
  'market.Away Team Exact Goals':'客队正确比分',

  // Selections
  'selection.Yes': '是',
  'selection.No': '不是',
  'selection.1+G': '主队获胜和两队得分',
  'selection.1+Ng': '主队获胜和客队未得分',
  'selection.X+G': '和局和两队得分',
  'selection.X+Ng': '和局和未得分',
  'selection.2+G': '客队获胜和两队得分',
  'selection.2+Ng': '客队获胜和主队无进球',

  'selection.1+Yes': '1+是',
  'selection.1+No': '1+否',
  'selection.X+Yes': 'X+是',
  'selection.X+No': 'X+否',
  'selection.2+Yes': '2+是',
  'selection.2+No': '2+否',
  'selection.TID_ANY_GOAL_SCORED_YES': '进球',
  'selection.TID_ANY_GOAL_SCORED_NO': '没有进球',

  'selection.U': '小',
  'selection.O': '大',
  'selection.1+O': '主场获胜和大',
  'selection.X+O': '和局和大',
  'selection.2+O': '客队获胜和大',
  'selection.1+U': '主场获胜和小',
  'selection.X+U': '和局和小',
  'selection.2+U': '客队获胜和小',
  'selection.TID_HOME_WIN': '主场',
  'selection.TID_AWAY_WIN': '客场',
  'selection.TID_DRAW': '和局',
  'selection.TID_HOME_WIN_OR_DRAW': '主队获胜或和局',
  'selection.TID_DRAW_OR_AWAY_WIN': '和局或客队获胜',
  'selection.TID_HOME_OR_AWAY_WIN': '主场或客队获胜',

  // Multi Bet Types
  'multiplebet.Singles': '单式投注',
  'multiplebet.Doubles': '二串一',
  'multiplebet.Trebles': '三倍三串一',
  'multiplebet.Accumulator': '累计',
  'multiplebet.Perm 4-Folds': '四串一',
  'multiplebet.Perm 5-Folds': '五串一',
  'multiplebet.Perm 6-Folds': '六串一',
  'multiplebet.Perm 7-Folds': '七串一',
  'multiplebet.Perm 8-Folds': '八串一',
  'multiplebet.Perm 9-Folds': '九串一',
  'multiplebet.Perm 10-Folds': '十串一',

  // Teams
  'sb-english.team.ARS': '阿森纳',
  'sb-english.team.AST': '阿思顿维拉',
  'sb-english.team.BHA': '布来顿',
  'sb-english.team.BOU': '博尼茅斯',
  'sb-english.team.BUR': '伯恩利',
  'sb-english.team.CHE': '切尔西',
  'sb-english.team.CRY': 'C. 宫',
  'sb-english.team.EVE': '埃弗顿',
  'sb-english.team.LEI': '莱切斯特',
  'sb-english.team.LIV': '利物浦',
  'sb-english.team.MNC': '曼彻斯特. C',
  'sb-english.team.MNU': '曼彻斯特联盟',
  'sb-english.team.NEW': '纽卡斯尔',
  'sb-english.team.SOU': '南安普敦',
  'sb-english.team.TOT': '托特纳姆',
  'sb-english.team.WAT': '沃特福德',
  'sb-english.team.WHU': '西汉姆',
  'sb-english.team.WOL': '伍尔弗',
  'sb-english.team.NOR': '诺维奇',
  'sb-english.team.SHU': '谢菲尔德. U',
  'sb-english.team.LEE': '利兹',
  'sb-english.team.FUL': '西布罗姆',
  'sb-english.team.WBA': '富勒姆',
}
